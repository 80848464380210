import styles from './mobileMenu.module.scss'
import { useTranslation } from 'next-i18next';
import Link from 'next/link'
import { Category } from '../../../interfaces/catalog-interfaces';
import { config } from '../../../config-merchant';

export default function MobileMenu(props: { categories: Category[], isActive: boolean, toggleMenu: any }) {

    const { t } = useTranslation('common');

    return (
        <nav className={props.isActive ? `${styles['nav-menu-mobile']} ${styles['nav-menu-mobile-open']}` : `${styles['nav-menu-mobile']} ${styles['nav-menu-mobile-close']}`}>
            <img className={styles["close"]}src="/assets/svg/close.svg" alt="close-menu" onClick={() => props.toggleMenu()} />
            <div className={styles["actions"]}>
                <Link href='/home'><img src="/assets/svg/logo-mobile.svg" alt="logo-mobile" onClick={() => props.toggleMenu()} /></Link>
            </div>
            <div>
                <ul>
                    {config.menu.map(item => {
                        if(item.external) {
                            return <li>
                                <a href={item.path} target={"_blank"} rel={"noreferrer"}>
                                    {t(item.name)}
                                </a>
                            </li>
                        }
                        if (item.path !== 'catalog') {
                            if (item.submenu.length === 0) {
                                return <Link key={item.path} href={'/' + item.path}><li onClick={() => props.toggleMenu()}>{t(item.name)}</li></Link>
                            } else {
                                return <Link key={item.path} href={'/' + item.path}>
                                    <li onClick={() => props.toggleMenu()}>
                                        {t(item.name)}
                                        <ul className={styles['submenu']}>
                                            {item.submenu.map(subitem => <Link key={subitem.path} href={'/' + item.path + '/' + subitem.path}><li onClick={() => props.toggleMenu()}>{t(subitem.name)}</li></Link>)}
                                        </ul>
                                    </li>
                                </Link>
                            }
                        } else {
                           return <li key={item.path}>{t(item.name)}
                                <ul className={styles['submenu']}>
                                    <div>
                                        <Link href="/catalog"><li onClick={() => props.toggleMenu()}>{t('CATALOG_ALL')}</li></Link>
                                        {props.categories.map((categorie) =>
                                            <Link key={categorie.categoryId} href={`/catalog?category=${categorie.categoryId}`}><li onClick={() => props.toggleMenu()}>{categorie.name}</li></Link>
                                        )}
                                    </div>
                                </ul>
                            </li>
                        }
                    })}
                </ul>
            </div>
        </nav>
    )
}
